import Chart from 'react-apexcharts';
/*
import ResponsiveContainer from 'react-apexcharts';
*/
const Tokonomics = () =>{
    return(
        <div className='tokonomics' id='tokenomics'>
            <div className='tokenomics-title-container'>
                </div>
            <div className='chart-container'>
            <Chart className='piechart'
            type='pie'
            width= '100%'
            height= 'auto'
            series={[350, 330, 332, 110,209, 242]}
            options={{
                labels: ['jan', 'feb', 'mar', 'april', 'may', 'june'],

                legend: {
                    labels:{
                        colors: ['#FFFFFF']
                    }
                },

                stroke: {
                  colors: ['#666']
                },

                responsive: [
                    {
                      breakpoint: 767,
                      options: {
                        legend: {
                          position: "bottom"
                        }
                      }
                    }
                  ]
                
            }}

            
            
            
            >

                
            </Chart>
            </div>
        </div>
    );
}

export default Tokonomics;