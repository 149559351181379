import './App.css';
import './Responsive.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import About from './components/About';
import Roadmap from './components/Roadmap';
import Tokonomics from './components/Tokonomics';
import ContactForm from './components/ContactForm';
import Nft from './components/Nft';
import Icons from './components/Icons';

function App() {
  return (
    <div className='body'>
      <Header/>,
      <About/>,
      <Tokonomics/>,
      <Roadmap/>
      <Nft/>
      <ContactForm/>
      <Icons/>
    </div>
  );
}

export default App;
