import emailjs from 'emailjs-com';
/*
import emailjs from '@emailjs/browser';
import React, { useState } from "react";


const Result = () =>{
  return(
    <p>Your Message has been succesfully sent.</p>
  )
}
*/
/*
const FORM_ENDPOINT = ""; // TODO - fill on the later step*/

const ContactForm = () => {
  
  /*const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <>
        <div className="text-2xl">Thank you!</div>
        <div className="text-md">We'll be in touch soon.</div>
      </>
    );
  }*/
/*
  const [result, showResult] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_eick8wc', 'template_32ntqbg', form.current, '4tarkWwFT1FS6sm7H')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      showResult(true);
  };
*/

function handleSubmit(e){
  e.preventDefault();

  emailjs.sendForm('service_eick8wc', 'template_32ntqbg', e.target, '4tarkWwFT1FS6sm7H')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset();
}


  

  return (
    
    <>
    <section id='contact-section'>

      <div className='container'>
      <div className='contact-title-container'>
    </div>

    <form
      action=""
      onSubmit={handleSubmit}
      /*action="contact.php"*/
      method="POST"
      target="_blank"
      className="contactForm"
  
    >

      <div className="mb-3 pt-0">
        <input id="input-field"
          type="text"
          placeholder="Your name"
          name="name"
          className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
          required
        />
      </div>
      <div className="mb-3 pt-0">
        <input id="input-field"
          type="email"
          placeholder="Email"
          name="email"
          className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
          required
        />
      </div>
      <div className="mb-3 pt-0">
        <textarea id="text-field"
          placeholder="Your message"
          name="message"
          className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
          required
        />
      </div>
      <div className="mb-3 pt-0">
        <button id="contact-btn"
          className="submit-btn bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="submit"
        >
        </button>


      {/*<div className='row'>{result ? <Result/>: null}</div> */}

      </div>
    </form>
      </div>
    
    </section>
    </>

    
    
  );
}

export default ContactForm;