import aboutLogo from '../assets/images/about/cards.png';



const About = () =>{
    return(
        <section id='vision-conatiner'>

            <div className='vision-conatiner'>
                <div className='title-container'>
                </div>

                <div className='lower-container'>
                    <div className='left-logo-container'>
                        <img src={aboutLogo} alt='Snake Logo'/>
                    </div>
                    <div className='right-para-container'>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore voluptas ex voluptatibus quia expedita nihil, quae repudiandae earum minima necessitatibus enim laborum! Doloremque consectetur doloribus, a deserunt magnam inventore accusamus.
                            <br></br>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem quas officia optio, aliquam eaque doloribus. Expedita nulla reprehenderit repellat sequi, quibusdam fugiat ex veritatis dolores! Pariatur ipsum deleniti exercitationem eligendi.
                        </p>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default About;