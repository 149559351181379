/*import video from "../assets/video.mp4";*/
import Logo from "../assets/images/header/title_logo.png";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import playBtn from '../assets/images/header/play.png';
/*import stakeBtn from '../assets/images/header/stake.png';*/
import download from '../assets/images/header/download.png';

const Header = () =>{

    return(
        <div className="header-container" id="home">

    <Navbar collapseOnSelect expand="lg" variant="dark" fixed="top" className="navbarBg">
      <Container className="logo-class">
        <Navbar.Brand href="https://egonverse.com" id="logo-heading">EGONVERSE</Navbar.Brand>
        {/*<Navbar.Brand href="#home"><img src={navbarLogo} /></Navbar.Brand>*/}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto links">
                <Nav.Link className="nav-items" href="#home">Home</Nav.Link>
                <Nav.Link className="nav-items" href="#vision-conatiner">About</Nav.Link>
                <Nav.Link className="nav-items" href="#tokenomics">Tokenomics</Nav.Link>
                <Nav.Link className="nav-items" href="#roadmap">Roadmap</Nav.Link>
                <Nav.Link className="nav-items" href="#">Faq</Nav.Link>
                <Nav.Link className="nav-items" href="#nft-container">NFT</Nav.Link>
                <Nav.Link className="nav-items" href="#contact-section">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


       {/* <video loop autoPlay muted src={video} />*/ }

        <div className="header-logo">
            <img src={Logo} />
        </div>


        <div className="header-btn-container">

{/*
            <div className="bttn">
                <a href="#"><img src={stakeBtn} /></a>
            </div>
    */}


            <div className="bttn">
                <a href="https://egonverse.com/EgonverseWebGL/"><img src={playBtn} /></a>
            </div>

            <div className="bttn">
                <a href="#popup1"><img src={download} /></a>
            </div>
        </div>


        <div id="popup1" className="overlay">
                <div className="popup2">
                <h2>Coming Soon</h2>
                <a className="close2" href="#">×</a>
                <div className="content2">
                </div>
                </div>
            </div>


        </div>
            
    );
}

export default Header;