import nftcard from '../assets/nftcard.png';


const Nft =()=>{
    return(
        <section id="nft-container">
            <div className="team-container">
                <div className="team-title-container">
                </div>

                <div className="team-members-container">
                    <div className="member1">
                        <img src={nftcard} alt='avatar'/>
                    </div>
                    <div className="member1">
                        <img src={nftcard} alt='avatar'/>
                    </div>
                    <div className="member1">
                        <img src={nftcard} alt='avatar'/>
                    </div>
                    <div className="member1">
                        <img src={nftcard} alt='avatar'/>
                    </div>
                    <div className="member1">
                        <img src={nftcard} alt='avatar'/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Nft;