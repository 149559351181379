import centerbar from "../assets/images/roadmap/roadmap_center_bar.png";
import image1 from "../assets/images/roadmap/roadmapcard1.png";
import image2 from "../assets/images/roadmap/roadmapcard2.png";
import image3 from "../assets/images/roadmap/roadmapcard3.png";
import image4 from "../assets/images/roadmap/roadmapcard4.png";
/*import hoverImage from "../assets/images/roadmap/roadmap_image.png";*/




const Roadmap = () =>{
    return(
        <section id="roadmap" className="section">

    <div className="container">
        <div className="row">        
            <div className="col-sm-12">
            <div className='roadmap-title-container'>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-12 roadmap-area">
                <div className="roadmap-tree">
             
                    <div className="row roadmap-cloumn pn_reverse hoverimg1">
                        <div className="roadmap-marker"></div>

                        <div className="heading" id="heading-1">
                            <h2>Phase 1</h2>
                          </div>

                        <div className="col-sm-12 col-md-12 col-lg-6 roadmap-desc  roadmap-dese-left">
                            <div className="doc-wraper">
                                <div className="roadmap_image inline">
                                    <img className="dogs" id="dog-1" src={image1} alt='hoverimage'/>
                                </div>

                                <div className="roadmap_centerbar inline">
                                    <img src={centerbar} alt='centerbar' />
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6 roadmap-desc roadmap-dese-right">
                            <div className="doc-wraper" style={{marginLeft: 80, marginTop: 40}}>
                                <p className="move roadmap-paragraphs para-1" value="Move image" id="para-1 mainFrameOne" style={{color: "white", fontFamily: 'Speedrush'}}>
                                   

                                    <ol>
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit </p></li> <br/>
                                        
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit Lorem ipsum</p></li><br/>
                                        
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit Lorem</p></li><br/>
                                
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum </p></li><br/>

                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit ipsum</p></li>
                                    </ol>
                                    
                                </p>
                            </div>
                        </div>
                     
                    </div>

                    <div className="row roadmap-cloumn hoverimg2">
                        <div className="roadmap-marker marker-off"></div>
                 
                        <div className="heading">
                            <h2>Phase 2</h2>
                          </div>

                        <div className="col-sm-12 col-md-12 col-lg-6 roadmap-desc  roadmap-dese-left" id="order3">
                            <div className="doc-wraper">
                                <div className="roadmap_image inline">
                                    <img className="dogs" id="dog-2" src={image2} alt='hoverimage'/>
                                </div>

                                <div className="roadmap_centerbar inline">
                                    <img src={centerbar} alt='centerbar' />
                                </div>
                            </div>
                        </div>
                  
                        <div className="col-sm-12 col-md-12 col-lg-6 roadmap-desc roadmap-dese-right" id="order4">
                            <div className="doc-wraper" style={{marginLeft: 80, marginTop: 40}}>
                                <p className="move roadmap-paragraphs" value="Move image" id="mainFrameTwo" style={{color: "white", fontFamily: 'Speedrush'}}>
                                    
                                    
                                    
                                    <ol>
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit </p></li> <br/>
                                        
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit Lorem ipsum</p></li><br/>
                                        
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit Lorem</p></li><br/>
                                
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum </p></li><br/>

                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit ipsum</p></li>
                                    </ol>
                                    
                                    
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row roadmap-cloumn hoverimg3">
                        <div className="roadmap-marker marker-off"></div>

                        <div className="heading">
                            <h2>Phase 3</h2>
                          </div>
                 
                        <div className="col-sm-12 col-md-12 col-lg-6 roadmap-desc  roadmap-dese-left" id="order3">
                            <div className="doc-wraper">
                                <div className="roadmap_image inline">
                                    <img className="dogs" id="dog-3" src={image3} alt='hoverimage' />
                                </div>

                                <div className="roadmap_centerbar inline">
                                    <img src={centerbar} alt='centerbar' />
                                </div>
                            </div>
                        </div>
                  
                        <div className="col-sm-12 col-md-12 col-lg-6 roadmap-desc roadmap-dese-right" id="order4">
                            <div className="doc-wraper" style={{marginLeft: 80, marginTop: 40}}>
                                <p className="move roadmap-paragraphs" value="Move image" style={{color: "white", fontFamily: 'Speedrush'}}>


                                    <ol>
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit </p></li> <br/>
                                        
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit Lorem ipsum</p></li><br/>
                                        
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit Lorem</p></li><br/>
                                
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum </p></li><br/>

                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit ipsum</p></li>
                                    </ol>


                                </p>
                            </div>
                        </div>
                    </div>



                    <div className="row roadmap-cloumn hoverimg3">
                        <div className="roadmap-marker marker-off"></div>

                        <div className="heading">
                            <h2>Phase 4</h2>
                          </div>
                 
                        <div className="col-sm-12 col-md-12 col-lg-6 roadmap-desc  roadmap-dese-left" id="order3">
                            <div className="doc-wraper">
                                <div className="roadmap_image inline">
                                    <img className="dogs" id="dog-3" src={image4} alt='hoverimage' />
                                </div>

                                <div className="roadmap_centerbar inline">
                                    <img src={centerbar} alt='centerbar' />
                                </div>
                            </div>
                        </div>
                  
                        <div className="col-sm-12 col-md-12 col-lg-6 roadmap-desc roadmap-dese-right" id="order4">
                            <div className="doc-wraper" style={{marginLeft: 80, marginTop: 40}}>
                                <p className="move roadmap-paragraphs" value="Move image" style={{color: "white", fontFamily: 'Speedrush'}}>


                                     <ol>
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit </p></li> <br/>
                                        
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit Lorem ipsum</p></li><br/>
                                        
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit Lorem</p></li><br/>
                                
                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum </p></li><br/>

                                        <li class="a" style={{paddingLeft: 40}}><p style={{fontSize: 20}}>Lorem ipsum dolor sit ipsum</p></li>
                                    </ol>

                                </p>
                            </div>
                        </div>
                    </div>

                


                </div>
            </div>
            
        </div>
    </div>
</section>

    );
}

export default Roadmap;